<script lang="ts" setup>
  import { BaseButton } from '@bt/design-system'
  import type Link from '@/types/Storyblok/Generic/Link'

  const { locale } = useI18n()
  const { blok } = defineProps<{ blok: any }>()

  const clickAction = (href: Link) => {
    if (href.story) {
      return navigateTo({
        path: formatSbPath(href.story.full_slug, locale.value)
      })
    }

    if (href.url) {
      return navigateTo(href.url, {
        external: true
      })
    }
  }
</script>

<template>
  <BaseButton
    :color="blok.color"
    :icon-color="blok.iconColor"
    :is-block="blok.isBlock"
    :prepend-icon="blok.icon"
    :title="blok.title"
    :type="blok.type"
    block-type="full-on-mobile"
    density="default"
    @click="clickAction(blok.href)"
  />
</template>
